.window {
    width: 100%;
    height: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: #ffffff3c;
    backdrop-filter: blur(10px);
}

.loader__box {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    z-index: 9999;
    box-shadow: 0 0 4px 3px #00000049;
}

.loader__box svg {
    width: 30px;
    z-index: 99999;
}