.login__box {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
}

.logo__box {
    height: 116px;
    padding-left: 84px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo__box img {
    width: 250px;
}

.content__box {
    padding: 59px 84px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.login__title {
    margin-bottom: 52px;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b2733;
}

.blue__text {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.2;
    letter-spacing: normal;
    color: #3190e9;
    margin-bottom: 40px;
    cursor: pointer;
}

.main__btn {
    border-radius: 5px;
    background: #11bf6a;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    transition: all .3s ease;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    box-shadow: 0 4px 6px 0 rgba(45, 129, 87, 0.5);
    cursor: pointer;
}

.main__btn:hover {
    background: #4ba97d;
}

.blue__text.nmt {
    margin-top: 20px;
    margin-bottom: 0;
}

.blue__text.c {
    text-align: center;
}

.error {
    margin-top: 6px;
    font-size: 14px;
    line-height: 1.43;
    color: #d41f1f;
}

.contacts__box {
    width: 100%;
    padding-inline: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.contacts__box p:first-child {
    font-size: 14px;
    line-height: 1.33;
    color: rgba(27, 39, 51, .7);
}

.contacts__box p :last-child {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b2733;
}

.warning__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 40px;
}

.warning__box h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
}

.warning__box p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

pizdakvalni {
    color: #3190e9;
    cursor: pointer;
}

.apps__box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.apps__box img {
    cursor: pointer;
}

.apps__box img:hover {
    opacity: 0.6;
}

.apps__box img:last-child {
    margin-left: 10px;
}

.sms__window {
    backdrop-filter: blur(10px);
    background: #00000034;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}

.sms__box {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #FFF;
    border-radius: 15px;
    min-width: 500px;
    position: relative;
}

.sms__box h2 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b2733;
}

.sms_inp__box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sms__part {
    width: calc(50% - 5px);
}

.input__box {
    display: flex;
    width: 100%;
    align-items: center;
    padding-inline: 16px;
    justify-content: center;
    height: 48px;
    border: 1px solid #d3d7e2;
    border-radius: 5px;
    transition: .1s ease-in-out;
    position: relative;
    margin-top:80px;
}

.input__box:hover {
    border: 1px solid #bac0d0;
}

.input__box:focus-within {
    border: 1px solid #646d75;
}

.input__box input {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: rgba(27, 39, 51, .9);
    line-height: 38px;
    border: none;
    outline: none;
    background: transparent;
    z-index: 3;
}

.input__box label {
    position: absolute;
    top: -45px;
    width: 450px;
    left: 0;
    line-height: 1.43;
    font-size: 14px;
    color: rgba(27, 39, 51, .7);
}

.sms__inp__lbl {
    position: absolute;
    left: 16px;
    color: #a7a7a7;
    font-weight: 300;
    z-index: 2;
}

.main__btn.mt {
    height: 48px;
    margin-top: 80px;
}

.sms__sended {
    clear: both;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: rgba(0,0,0,.7);
    width: 100%;
    margin-top: 10px;
}

.error.sms {
    width: 100%;
    position: relative;
    top: 15px;
    left: 0;
}

@media (max-width: 768px) {
    .login__box {
        width: 100%;
    }

    .content__box {
        padding: 20px;
    }

    .apps__box {
        margin-bottom: 50px;
    }
    .logo__box {
        align-items: center;
        height: 50px;
    }
    .login__box img:first-child {
        height: 25px;
    }

    .login__title {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .sms__box {
        min-width: 95%;
        max-width: 95%;
        box-sizing: border-box;
        padding: 20px;
    }

    .sms_inp__box {
        flex-direction: column;
    }

    .sms__part {
        width: 100%;
    }

    .main__btn.mt {
        margin-top: 10px;
    }

    .input__box label {
        width: 100%;
        top: -65px;
    }

    .sms__sended {
        margin-top: 20px;
    }
}