.open__btn {
    width: 60px;
    height: 60px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: rgb(17, 191, 106);
    z-index: 9999;
    user-select: none;
    cursor: pointer;
}

.open__btn img {
    width: 40px;
}

.window {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 20px;
    width: 300px;
    height: 400px;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px #00000057;
    z-index: 99999;
    background: #FFF;
}

.oper__online {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    padding-left: 10px;
    width: calc(100% + 40px);
    color: #000;
    border-bottom: 1px solid #bfbfbf;
    position: relative;
}

.online__icon {
    width: 7px;
    height: 7px;
    background: #2cff19;
    border-radius: 50%;
    margin-left: 10px;
}

.cross {
    position: absolute;
    right: 15px;
    font-family: monospace;
    font-size: 20px;
    user-select: none;
    cursor: pointer;
}

.messages__box {
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
    height: calc(100% - 80px);
}

.messages__box::-webkit-scrollbar {
    display: none;
}

.message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    background: #f6f6f6;
    margin-bottom: 20px;
    word-wrap: break-word;
}

.sender {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.text {
    word-wrap: break-word;
    max-width: 100%;
}

.input__box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}

.input__box input {
    width: 100%;
    height: 30px;
    padding-inline: 10px;
    padding-left: 15px;
    border-radius: 20px;
    outline: none;
    border: 1px solid #bfbfbf;
    transition: .3s ease-in-out;
    font-weight: 500;
}

.input__box input:focus {
    border: 1px solid #00A9FF;
}

.send__box {
    width: 30px;
    height: 30px;
    background: rgb(17, 191, 106);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.send__box img {
    z-index: 9999999;
    width: 20px;
}

@media (max-width: 1000px) {
    .window {
        width: 100vw;
        height: 100vh;
        bottom: 0;
        right: 0;
        border-radius: 0;
    }

    .oper__online {
        height: 50px;
        font-size: 20px;
    }

    .cross {
        font-size: 20px;
    }

    .sender {
        font-size: 20px;
    }

    .text {
        font-size: 18px;
    }

    .input__box {
        position: absolute;
        bottom: 30px;
        left: 0;
        background: #FFF;
        width: 100vw;
        height: 50px;
        padding-top: 10px;
    }

    .input__box input {
        width: 70%;
        height: 40px;
    }

    .send__box {
        width: 40px;
        height: 40px;
    }

    .messages__box {
        padding-bottom: 50px;
    }
}