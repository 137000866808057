@font-face {
  font-family: "SeroPro";
  src: url(media/SeroPro.woff2);
}

@font-face {
  font-family: "SeroPro";
  src: url(media/SeroPro-Bold.woff2);
  font-weight: 700;
}

@font-face {
  font-family: "SeroPro";
  src: url(media/SeroPro-Italic.woff2);
  font-style: italic;
}

@font-face {
  font-family: "SeroPro";
  src: url(media/SeroPro-Light.woff2);
  font-weight: 300;
}

@font-face {
  font-family: "SeroPro";
  src: url(media/SeroPro-Medium.woff2);
  font-weight: 500;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SeroPro";
}

body, .root, .App {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

