.input__box {
    display: flex;
    width: 100%;
    align-items: center;
    padding-inline: 16px;
    justify-content: center;
    height: 40px;
    border: 1px solid #d3d7e2;
    border-radius: 5px;
    transition: .1s ease-in-out;
    position: relative;
}

.input__box:hover {
    border: 1px solid #bac0d0;
}

.input__box:focus-within {
    border: 1px solid #646d75;
}

.input__box input {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: rgba(27, 39, 51, .9);
    line-height: 38px;
    border: none;
    outline: none;
}

.input__box label {
    position: absolute;
    top: -25px;
    left: 0;
    line-height: 1.43;
    font-size: 14px;
    color: rgba(27, 39, 51, .7);
}

.input__box.password {
    padding-right: 48px;
    margin-top: 50px;
}

.eye {
    position: absolute;
    right: 12px;
    width: 24px;
    cursor: pointer;
}