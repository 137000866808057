.board {
    width: 75%;
    color: rgba(0, 0, 0, 0.9);
    background-color: rgb(239, 246, 249);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 31px 15px 15px 56px;
}

.board__box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.text__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40%;
    color: #1b2733;
}

.text__box h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 135%;
    margin-top: 15px;
    margin-bottom: 30px;
}

.text__box h4 {
    color: #1b2733;
    margin-bottom: 8px;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
}

.text__box p {
    margin-bottom: 24px;
}

.img__box {
    width: 45%;
}

.img__box:last-child img {
    width: 350px;
}

@media (max-width: 768px) {
    .board {
        display: none;
    }   
}